
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'

@Component({
  components: { FieldTitle },
})
  export default class GTimeSelector extends Vue {
  @Prop({ type: Object, default: () => ({ start: '', end: '' }) }) value!: { start: string, end: string };
  @Prop({ type: String, default: '' }) title!: string;

  start: string = '';
  end: string = '';
  masked: boolean = false;

  get timeMask () {
    return {
      mask: '##:##',
      kind: this.masked ? 'external' : 'both',
      eager: false,
      reversed: false,
      tokensReplace: false,
    }
  }

  @Watch('start')
  @Watch('end')
  onTimeChange () {
    if (this.isValidTime(this.start) && this.isValidTime(this.end)) {
      this.emitTime()
    }
  }

  @Watch('value', { immediate: true })
  onValueChange () {
    this.start = this.value.start
    this.end = this.value.end
  }

  validateStart (): string | boolean {
    if (!this.start) return 'El campo es obligatorio'
    return this.isValidTime(this.start) || 'Tiempo inicio inválido'
  }

  validateEnd (): string | boolean {
    if (!this.end) return 'El campo es obligatorio'
    return this.isValidTime(this.end) || 'Tiempo fin inválido'
  }

  isValidTime (time: string): boolean {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/
    return timePattern.test(time)
  }

  emitTime () {
    this.$emit('input', { start: this.start, end: this.end })
  }
  }
