
  import { Component, Prop } from 'vue-property-decorator'
  import { bankDataFull } from '@/store/persons/person'
  import { truncateLongText } from '@/utils/general'
  import { BaseCustomForm } from '@/components/person/BaseAlignment'

@Component
  export default class BankDataAlignment extends BaseCustomForm {
  @Prop({ type: Array, default: () => [] }) bankData!: Array<bankDataFull>;
  @Prop({ type: Boolean, default: false }) allowEdit!: boolean

  truncateText (text: string): string {
    return truncateLongText(text, 40)
  }
  }
